import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { VehicleOrderTrackingContent } from '@views/vehicle-order-tracking-view/hooks/use-vehicle-order-tracking-content';
import { InputField } from '@common/form-fields/form-fields';
import { PrimaryButton, SecondaryButton } from '@common/index';
import { useVinValidation } from '@sections/add-vehicle/hooks/use-vin-validation';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import { CvotErrors } from '@views/vehicle-order-tracking-view/components/vehicle-order-tracking-errors';
import { findPathByAlias } from '@routes/routesList';
import { useNavigate } from 'react-router-dom';
import { scriptService } from '@services/script-service/script-service';
import CustomerOrderTrackingService from '@services/customer-order-tracking-service/customer-order-tracking-service';
import HttpService from '@services/http-service/http-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import EncryptionService from '@/services/encryption-service/encryption-service';
import ServerSideService from '@services/server-side-service/server-side-service';
import { CVOT_AUTHENTICATED_ORDER_STORAGE_KEY } from '@constants';
import { useAnalytics } from '@/hooks/use-analytics';

interface VehicleOrderTrackingComponentProps {
    addVehicleContent: AddVehicleContent;
    vehicleOrderTrackingContent: VehicleOrderTrackingContent;
    vin: string;
    setVin: Dispatch<SetStateAction<string>>;
    customerOrderNumber: string;
    setCustomerOrderNumber: Dispatch<SetStateAction<string>>;
    recaptchaToken: string;
    setRecaptchaToken: Dispatch<SetStateAction<string>>;
    errors: CvotErrors;
    setErrors: Dispatch<SetStateAction<CvotErrors>>;
    isCanada?: boolean;
}

export interface InputValidationParams {
    value: string;
    field: string;
    pattern: string;
    message: string;
}

const VehicleOrderTrackingComponent = (
    props: VehicleOrderTrackingComponentProps
) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [
        customerOrderTrackingServiceError,
        setCustomerOrderTrackingServiceError,
    ] = useState<boolean>(false);
    const [fireAnalytics] = useAnalytics();

    const {
        vinErrorState,
        vinErrorMessage,
        validateVINOrReg,
        isValidForNonEu,
        resetVinErrorState,
    } = useVinValidation(props.addVehicleContent, false);
    const isDisabled: boolean =
        props.errors['customerOrderNumber']?.status ||
        props.errors['vin']?.status ||
        !!(props.vin && !props.customerOrderNumber) ||
        !!(!props.vin && props.customerOrderNumber) ||
        !!(!props.vin && !props.customerOrderNumber) ||
        props.recaptchaToken === null;

    const encryptionService = new EncryptionService(
        process.env.REACT_APP_CVOT_ENCRYPTION_KEY
    );

    const isValidInputForField = ({
        value,
        field,
        pattern,
        message,
    }: InputValidationParams): boolean => {
        const regex = new RegExp(pattern);

        if (!regex.test(value)) {
            props.setErrors({
                [field]: {
                    status: true,
                    message: message,
                },
            });
        } else if (regex.test(value) && props.errors[field]?.status) {
            props.setErrors({
                [field]: {
                    status: false,
                    message: '',
                },
            });
        }
        return regex.test(value);
    };

    const isVinValid = (value: string): boolean => {
        const regex = new RegExp(
            props.vehicleOrderTrackingContent.vinValidationPattern
        );
        if (!regex.test(value)) {
            props.setErrors({
                vin: { status: vinErrorState, message: vinErrorMessage },
            });
        } else if (regex.test(value) && props.errors['vin']?.status) {
            resetVinErrorState();
        }
        return regex.test(value);
    };

    const handleSubmit = (): void => {
        setCustomerOrderTrackingServiceError(false);
        if (validateVINOrReg(props.vin)) {
            setIsLoading(true);
            new CustomerOrderTrackingService(HttpService)
                .getOrderTrackingStatus(
                    props.vin,
                    props.customerOrderNumber.toUpperCase(),
                    props.recaptchaToken,
                    props.isCanada
                )
                .then((response) => {
                    if (response.customerOrder.orderInfo) {
                        localStorage.setItem(
                            CVOT_AUTHENTICATED_ORDER_STORAGE_KEY,
                            ''
                        );
                        sessionStorage.setItem(
                            'cvot',
                            JSON.stringify(response.customerOrder)
                        );
                        navigate(
                            findPathByAlias('VehicleOrderTrackingStatusView')
                        );
                        fireAnalytics('cvotSuccessOnclickEvent');
                    } else {
                        fireAnalytics('cvotErrorOnclickEvent');
                        return new Error('Order not found');
                    }
                })
                .catch(() => {
                    window['grecaptcha'].reset();
                    setCustomerOrderTrackingServiceError(true);
                    fireAnalytics('cvotErrorOnclickEvent');
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetOrderNumberErrorState = (): void => {
        props.setErrors({
            customerOrderNumber: { status: false, message: '' },
        });
    };

    const onRecaptchaCompleted = (response: any) => {
        props.setRecaptchaToken(response);
        console.log(response);
        return response;
    };

    useEffect(() => {
        if (isVinValid(props.vin) && isDisabled) {
            props.setErrors({ vin: { status: false, message: '' } });
        }
    }, [props.vin]);

    useEffect(() => {
        (window as any).onRecaptchaCompleted = onRecaptchaCompleted;
        scriptService.loadRecaptchaScript();
    }, []);

    useEffect(() => {
        if (ServerSideService.isClientSide()) {
            const getQueryParams = (): Record<string, string> => {
                const params: Record<string, string> = {};
                const queryString = window.location.search.slice(1);
                const pairs = queryString.split('&');
                pairs.forEach((pair) => {
                    const [key, value] = pair.split('=');
                    params[key] = decodeURIComponent(value);
                });
                return params;
            };

            const params = getQueryParams();
            if (params.vin && params.don) {
                props.setVin(encryptionService.decrypt(params.vin));
                props.setCustomerOrderNumber(
                    encryptionService.decrypt(params.don)
                );
            }
        }
    }, []);

    return (
        <>
            <div
                className="vehicle-order-tracking__component"
                data-testid="vehicle-order-tracking-component"
            >
                <h1
                    className="vehicle-order-tracking__header-text"
                    data-testid="vehicle-order-tracking-header-text"
                >
                    {props.vehicleOrderTrackingContent.headerText}
                </h1>

                {props.vehicleOrderTrackingContent.accessorySubtitleText && (
                    <div
                        className="accessory-order-section"
                        data-testid="accessory-order-section"
                    >
                        <h2
                            className="vehicle-order-tracking__subtitle-text"
                            data-testid="accessory-order-subtitle-text"
                        >
                            {
                                props.vehicleOrderTrackingContent
                                    .accessorySubtitleText
                            }
                        </h2>

                        <h3
                            className="accessory-order-nav-description-section"
                            data-testid="accessory-order-nav-description-section"
                        >
                            {
                                props.vehicleOrderTrackingContent
                                    .accessoryDescriptionText
                            }
                        </h3>

                        <SecondaryButton
                            id="accessory-order-tracking-nav-button"
                            className="accessory-order-tracking-nav-button fmc-button fmc-button--no-shadow"
                            dataTestId="accessory-order-tracking-nav-button"
                            aria-Label={
                                props.vehicleOrderTrackingContent
                                    .accessoryOrdersButtonAriaLabel
                            }
                            link={
                                props.vehicleOrderTrackingContent
                                    .accessoryOrdersNavPath
                            }
                            children={
                                <>
                                    {
                                        props.vehicleOrderTrackingContent
                                            .accessoryOrdersButtonLabelText
                                    }{' '}
                                    <span className="fds-icon fds-font--ford-icons__chevron-right btn-chevron-offset-right"></span>
                                </>
                            }
                        ></SecondaryButton>
                    </div>
                )}

                <h2
                    className="vehicle-order-tracking__subtitle-text"
                    data-testid="vehicle-order-tracking-subtitle-text"
                >
                    {props.vehicleOrderTrackingContent.subtitleText}
                </h2>

                <div
                    className="vehicle-order-tracking__numbered-instructions-section"
                    data-testid="vehicle-order-tracking-numbered-instructions-section"
                    dangerouslySetInnerHTML={{
                        __html: props.vehicleOrderTrackingContent
                            .numberedInstructionsSection,
                    }}
                ></div>
                <InputField
                    id="customer-order-number"
                    name="customerOrderNumber"
                    className={`${
                        props.errors['customerOrderNumber']?.status
                            ? 'input-error'
                            : ''
                    }`}
                    dataTestId="customer-order-number-input"
                    label={
                        props.vehicleOrderTrackingContent
                            .customerOrderNumberLabelText
                    }
                    showLabel={false}
                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                        props.setCustomerOrderNumber(e.target.value);

                        isValidInputForField({
                            value: e.target.value,
                            field: 'customerOrderNumber',
                            pattern:
                                props.vehicleOrderTrackingContent
                                    .customerOrderNumberValidationPattern,
                            message:
                                props.vehicleOrderTrackingContent
                                    .customerOrderNumberErrorMessage,
                        });
                    }}
                    onBlur={() => {
                        if (props.customerOrderNumber.length === 0) {
                            resetOrderNumberErrorState();
                        }
                    }}
                    placeholder={
                        props.vehicleOrderTrackingContent
                            .customerOrderNumberPlaceholderText
                    }
                    validationPattern={
                        props.vehicleOrderTrackingContent
                            .customerOrderNumberValidationPattern
                    }
                    value={props.customerOrderNumber}
                    error={props.errors['customerOrderNumber']}
                    fromCvot
                />
                <InputField
                    id="vin"
                    name="vin"
                    className={`${
                        props.errors['vin']?.status ? 'input-error' : ''
                    }`}
                    dataTestId="vin-input"
                    label={props.vehicleOrderTrackingContent.vinLabelText}
                    showLabel={true}
                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                        props.setVin(e.target.value);
                        validateVINOrReg(e.target.value);
                        isValidForNonEu(e.target.value);
                        isVinValid(e.target.value);
                    }}
                    onBlur={() => {
                        if (props.vin.length === 0) {
                            resetVinErrorState();
                        }
                    }}
                    placeholder={
                        props.vehicleOrderTrackingContent.vinPlaceholderText
                    }
                    validationPattern={
                        props.vehicleOrderTrackingContent.vinValidationPattern
                    }
                    validationRules={[
                        'validateNonEmpty',
                        'validateMatchesPattern',
                    ]}
                    value={props.vin}
                    error={{
                        status: vinErrorState,
                        message: vinErrorMessage,
                    }}
                    fromCvot
                />

                <div
                    className="vehicle-order-tracking__recaptcha-container"
                    data-testid="vehicle-order-tracking-recaptcha"
                >
                    <form action="?" method="POST">
                        <div
                            className="g-recaptcha"
                            data-sitekey="6Lcp2G4UAAAAAOGZH0qdD_EFAm-OlFm_CbTmkVnQ"
                            data-callback="onRecaptchaCompleted"
                        ></div>
                    </form>
                </div>

                <h2
                    className="vehicle-order-tracking__terms-and-conditions-header-text"
                    data-testid="vehicle-order-tracking-terms-and-conditions-header-text"
                >
                    {
                        props.vehicleOrderTrackingContent
                            .termsAndConditionsHeaderText
                    }
                </h2>
                <div
                    className="vehicle-order-tracking__terms-and-conditions-body-text"
                    data-testid="vehicle-order-tracking-terms-and-conditions-body-text"
                    dangerouslySetInnerHTML={{
                        __html: props.vehicleOrderTrackingContent
                            .termsAndConditionsBodyText,
                    }}
                ></div>
                <div
                    className="vehicle-order-tracking-error"
                    data-testid="vehicle-order-tracking-error"
                >
                    {`${
                        customerOrderTrackingServiceError
                            ? props.vehicleOrderTrackingContent
                                  .customerOrderTrackingServiceError
                            : ''
                    }`}
                </div>
                <PrimaryButton
                    id="vehicle-order-tracking-accept-button"
                    testId="vehicle-order-tracking-accept-button"
                    ariaLabel={
                        props.vehicleOrderTrackingContent.acceptButtonAriaLabel
                    }
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {props.vehicleOrderTrackingContent.acceptButtonLabelText}
                </PrimaryButton>
            </div>
            {isLoading && <ActivityIndicator className="full-height" />}
        </>
    );
};

export default VehicleOrderTrackingComponent;
