import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ErrorPage from '@sections/profile-error/error-page';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useProfileDataLite } from '@sections/account-portal/hooks/use-profile-data';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import SignInAndSecurity from '@sections/sign-in-and-security/sign-in-and-security';
import ReturnButton from '@sections/return-button/return-button';
import './sign-in-credentials-view.scss';
import {
    SecurityContent,
    useSecurityContent,
} from '@sections/sign-in-and-security/hook/use-security-content';
import MFAService, { MFAOptions } from '@services/mfa-service/mfa-service';
import HttpService from '@services/http-service/http-service';
import { findPathByAlias } from '@routes/routesList';
import AuthenticationService from '@services/authentication-service/authentication-service';
import {
    FORDPASS_AUTHORIZED_DEEPLINK,
    LINCOLNWAY_AUTHORIZED_DEEPLINK,
} from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useAnalytics } from '@/hooks/use-analytics';
import { useConnectedStatusContext } from '@contexts/connectedStatusContext';

const SignInCredentialsView = () => {
    const { setNotificationContext } = useNotificationContext();
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();
    const httpService = HttpService;
    const authenticationService = new AuthenticationService();
    const mfaService: MFAService = new MFAService(httpService, appConfig);
    const profileDataLite = useProfileDataLite(true);
    const securityContent: SecurityContent = useSecurityContent();
    const appInformationContent = useAppInformationContent();
    const scrollUtil = new ScrollUtil();
    const queryParams = new URLSearchParams(useLocation().search);
    if (queryParams.get('locale') != 'en-ae') {
        useAnalytics('ownerAccountSignIn');
    }
    const fromWebview = queryParams.get('webview') || undefined;
    const fromAppLogout = queryParams.get('appLogout');
    const [showMFASection, setShowMFASection] = useState<boolean>(false);
    const [mfaOptions, setMfaOptions] = useState<MFAOptions[]>(null);
    const [userRequiresMFA, setUserRequiresMFA] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>(null);
    const [errorNotificationMessage, setErrorNotificationMessage] =
        useState<string>(null);
    const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
    const isFord = appConfig.getBrand() === 'ford';
    const isLincoln = appConfig.getBrand() === 'lincoln';
    const [fullProfileLite, setFullProfileLite] = useState(null);
    const { getConnectedStatus } = useConnectedStatusContext();

    const [profileServiceError, setProfileError] = useState({
        status: false,
        message: '',
    });
    const showSignInAndSecurity = Boolean(userId && securityContent);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        if (fromAppLogout) {
            logoutUsingDeepLink();
        }
    }, []);

    authenticationService.updateState(
        findPathByAlias('SignInCredentialsView') + useLocation().search
    );

    seoService.set(
        {
            pageTitle: 'Sign In and Security',
            applicationName: appInformationContent?.applicationName,
            pageDescription: 'Sign In and Security Description',
        },
        serverContext
    );

    const logoutUsingDeepLink = () => {
        if (isFord) window.location.assign(FORDPASS_AUTHORIZED_DEEPLINK);
        else if (isLincoln)
            window.location.assign(LINCOLNWAY_AUTHORIZED_DEEPLINK);
    };

    const getMFAOptions = () => {
        clearErrorNotification();
        mfaService
            .getMFAOptions()
            .then((mfaServiceResponse) => {
                if (mfaServiceResponse) {
                    setShowMFASection(true);
                    setUserRequiresMFA(mfaServiceResponse.userRequiresMFA);
                    setMfaOptions(mfaServiceResponse.mfaOptions);
                } else {
                    handleGetMFAError();
                }
            })
            .catch(() => handleGetMFAError());
    };

    const handleGetMFAError = () => {
        setShowMFASection(false);
        setErrorNotification(
            securityContent.mfaGetOptionsErrorNotificationMessage
        );
    };

    const setErrorNotification = (message: string) => {
        if (isLincoln) {
            if (message)
                setNotificationContext(NotificationType.Error, false, message);
        } else {
            setErrorNotificationMessage(message);
        }
    };

    const clearErrorNotification = () => {
        if (isLincoln) {
            setNotificationContext(NotificationType.None, false);
        } else {
            setErrorNotificationMessage(null);
        }
    };

    useEffect(() => {
        if (profileDataLite?.message) {
            setProfileError({
                status: true,
                message: profileDataLite.message,
            });
        } else if (profileDataLite) {
            setFullProfileLite(profileDataLite);
            setUserId(profileDataLite.profile.userId);

            scrollUtil.scrollPageToTop();
            setProfileError({
                status: false,
                message: '',
            });

            if (queryParams.get('locale') != 'en-ae') {
                getMFAOptions();
            }
        }
    }, [profileDataLite]);

    useEffect(() => {
        if (fullProfileLite) {
            getConnectedStatus(fullProfileLite);
        }
    }, [fullProfileLite]);

    return (
        <div className="sign-in-credentials-view">
            <ReturnButton
                returnButtonContent={{
                    returnText: showErrorPage
                        ? securityContent?.errorPageReturnButtonText
                        : securityContent?.returnButtonText,
                }}
                fromSignInAndSecurityErrorPage={showErrorPage}
                onClick={() => setShowErrorPage(false)}
            />

            {showSignInAndSecurity && (
                <SignInAndSecurity
                    userId={userId}
                    mfaService={mfaService}
                    showMFASection={showMFASection}
                    mfaOptions={mfaOptions}
                    setMFAOptions={setMfaOptions}
                    userRequiresMFA={userRequiresMFA}
                    setUserRequiresMFA={setUserRequiresMFA}
                    securityContent={securityContent}
                    fromWebview={!!fromWebview}
                    errorNotificationMessage={errorNotificationMessage}
                    setErrorNotification={setErrorNotification}
                    clearErrorNotification={clearErrorNotification}
                    showErrorPage={showErrorPage}
                    setShowErrorPage={setShowErrorPage}
                    scrollUtil={scrollUtil}
                    logoutUsingDeepLink={logoutUsingDeepLink}
                    isLincoln={isLincoln}
                />
            )}
            {!showSignInAndSecurity && profileServiceError.status ? (
                <ErrorPage />
            ) : null}
            {!showSignInAndSecurity && !profileServiceError.status ? (
                <ActivityIndicator className="full-height" />
            ) : null}
        </div>
    );
};

export default SignInCredentialsView;
