import React, { createContext, useCallback, useContext, useState } from 'react';
import VehicleTypeService, {
    findNewestVehicle,
    VehicleType,
} from '@services/vehicle-type-service/vehicle-type-service';
import {
    ProfileWithVehiclesResponse,
    VehicleDetail,
} from '@models/profile-with-vehicles';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface ConnectedStatusContextProps {
    isConnected: boolean;
    vin: string;
    firstName: string;
    setConnectedStatusContext?: (updates: ConnectedStatusContextProps) => void;
    getConnectedStatus?: (profile: ProfileWithVehiclesResponse) => void;
}

export const ConnectedStatusContext = createContext({
    isConnected: false,
    vin: null,
    firstName: null,
    setConnectedStatusContext: () => null,
    getConnectedStatus: () => null,
} as any);

export const ConnectedStatusProvider = ({ children }) => {
    const [connectedStatusContext, setConnectedVehicleStatusContext] =
        useState<ConnectedStatusContextProps | null>({
            isConnected: false,
            vin: null,
            firstName: null,
        });
    const appConfig = new AppConfigurationService();

    const setConnectedStatusContext = (
        connectedStatusContextUpdates: ConnectedStatusContextProps
    ) => {
        setConnectedVehicleStatusContext((prevState) => {
            return {
                ...prevState,
                ...connectedStatusContextUpdates,
            };
        });
    };

    const getConnectedStatus = (profile: ProfileWithVehiclesResponse): void => {
        const newestVehicle: VehicleDetail = findNewestVehicle(
            profile.vehicles
        );
        const vehicleTypeService = new VehicleTypeService();

        if (
            appConfig.currentLanguageRegionCode !== 'en-us' ||
            appConfig.brand === 'lincoln'
        ) {
            return;
        }

        vehicleTypeService
            .requestVehicleType(
                newestVehicle.vin,
                appConfig.currentLanguageRegionCode,
                appConfig.current3LetterCountryCapitalizedCode
            )
            .then((response: VehicleType) => {
                setConnectedStatusContext({
                    isConnected: response.isConnected ?? false,
                    vin: response?.vehicleData?.vin,
                    firstName: profile.profile.firstName,
                });
            });
    };

    const getContext = useCallback(() => {
        return {
            connectedStatusContext,
            setConnectedStatusContext,
            getConnectedStatus,
        };
    }, [connectedStatusContext]);

    return (
        <ConnectedStatusContext.Provider value={getContext()}>
            {children}
        </ConnectedStatusContext.Provider>
    );
};

export const useConnectedStatusContext = () =>
    useContext(ConnectedStatusContext);

export default ConnectedStatusProvider;
